import * as React from "react"
import { Link } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout simpleHeader={true}>
    <SEO title="404 - Page non trouvée" />
    <div className="fluid-grid text-center py-12">
      <h1 className="title-section mb-4 mx-auto"><span className="knockout-title block -mb-4">404</span>Page non trouvée</h1>
      <p className="paragraph">La page que vous recherchez n'existe pas.</p>

      <Link to="/" className="button mt-8">Retour à l'observatoire</Link>
    </div>
  </Layout>
)

export default NotFoundPage
